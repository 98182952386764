import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"
import { Link } from "gatsby"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About Georgia</h2>
          <p>Since 1937, Georgia Boot has been building America's Hardest Working Boot. Purpose-built to meet the needs of the longest shift and toughest job, we're proud to make the boots you need to get the job done.</p>
          <p>At Georgia Boot, we pride ourselves on crafting high-quality, durable boots that keep you protected and comfortable. Whether you're working on the farm, landscape, construction, or DIY projects, we continuously innovate to ensure you stay comfortable in the toughest conditions all day long.</p>
          <div className={brandBodyStyles.michelin}>
          <a href="https://www.georgiaboot.com/michelin/" title="Michelin Safety Footwear">
            <StaticImage
              src="../../assets/images/index/brand-headers/georgia/michelin-saftey-footwear-logo.jpg"
              alt="Michelin Safety Footwear"
              imgStyle={{width: `100%`, height: `100%`}}
              style={{width: `100%`, height: `100%`}}
            />
          </a>
          </div>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-body/georgia/georgia-second-image.jpg"
            className={brandBodyStyles.halfImage}
            alt="Georgia Construction Site"
            imgStyle={{width: `100%`, height: `100%`}}
            style={{width: `100%`, height: `100%`}}
          />
        </div>
      </div>
      <div className={brandBodyStyles.highlightSection}>
        <div className={brandBodyStyles.imageGrid}>
            <StaticImage
                src="../../assets/images/index/brand-body/georgia/georgia-square-01.jpg"
                className={brandBodyStyles.halfImage.one}
                alt=""
                imgStyle={{width: `100%`, height: `100%`}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/georgia/georgia-square-02.jpg"
                className={brandBodyStyles.halfImage.two}
                alt=""
                imgStyle={{width: `100%`, height: `100%`}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/georgia/georgia-square-03.jpg"
                className={brandBodyStyles.halfImage.three}
                alt=""
                imgStyle={{width: `100%`, height: `100%`}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/georgia/georgia-square-04.jpg"
                className={brandBodyStyles.halfImage.four}
                alt=""
                imgStyle={{width: `100%`, height: `100%`}}
                style={{width: `100%`, height: `100%`}}
              />
          </div>
          <div className={brandBodyStyles.highlights}>
            <h3>Highlights</h3>
            <h4>Key Product Categories</h4>
            <p>Georgia Boot is a brand for the hardworking individuals who are building the world around us. Our boots are made for all work environments, covering everything from <a href="https://www.georgiaboot.com/farm-boots/" title="Georgia Farm & Ranch Boots">farm and ranch</a> to <a href="https://www.georgiaboot.com/work-boots/" title="Georgia Work Boots">construction</a>, <a href="https://www.georgiaboot.com/loggers/" title="Georgia Logging Boots">logging</a>, <a href="https://www.georgiaboot.com/georgia/collections/landscaping-work-boots/" title="Georgia Landscaping Boots">landscaping</a>, or <a href="https://www.georgiaboot.com/casual/" title="Georgia Casual Boots">casual</a> wear at the end of a long workday. The key silhouettes that we are known and trusted for are our <a href="https://www.georgiaboot.com/wedge/" title="Georgia Wedge Boots">wedges</a>, <a href="https://www.georgiaboot.com/loggers/" title="Georgia Logger Boots">loggers</a>, and <a href="https://www.georgiaboot.com/romeo/" title="Georgia Romeos">Romeos</a>. With this in mind, we strive to develop new products that stay true to our heritage while incorporating the latest comfort and <a href="" title="">technology advancements</a>.</p>
            <h4>Georgia Comfort System</h4>
            <p>The Georgia Boot comfort system is a line of aftermarket <a href="https://www.georgiaboot.com/insoles/" title="Georgia Comfort Footbeds">comfort footbeds</a> available for purchase since comfort is the main purchase driver for our consumers. The Georgia Boot <a href="https://www.georgiaboot.com/insoles/georgia-boot-amp-insole/GB00110.html" title="Georgia Advanced Memory Polyurethane Insole">AMP (Advanced Memory Polyurethane) Insole</a> has become a favorite among professionals due to its unmatched comfort.</p>
            <h4>Michelin Safety Footwear</h4>
            <p>Georgia Boot is a proud partner and manufacturer of a line of Safety Footwear under license from MICHELIN&reg;. <a href="https://www.georgiaboot.com/michelin/" title="Michelin Safety Footwear">Michelin Safety Footwear</a> and the respective wordmark are used with permission of Michelin North America, Inc. All rights reserved.</p>
            <h4>4-H</h4>
            <p>Since 2019, we have partnered with the <a href="https://www.georgiaboot.com/4-h/" title="National 4-H Council">National 4-H Council</a> to provide opportunities for youth across the country to share their experiences and ensure that all kids have access to opportunity and skills-based learning.</p>
          </div>
      </div>
      {/* <div className={brandBodyStyles.strategy}>
        <h4>Georgia Boot Strategy</h4>
        <p>Durango Boot excels at providing comfortable and affordable footwear to hard-working people across the USA as the world. With premium footbed and outsole technology and time-tested manufacturing techniques, Durango boots are the ideal footwear to stock on local retailer shelves and the best tradespersons closets.</p>
        <div className={brandBodyStyles.strategyButtons}>
          <Link to="/customer-resources/join-our-family-of-authorized-dealers/" title="Become a Dealer" className="CTAblack">
            Become a Dealer
          </Link>
          <Link to="/customer-resources/for-our-consumers/" title="Customer Resources" className="CTAalt">
            Customer Resources
          </Link>
        </div>
      </div> */}
    </div>
  )
}

export default BrandBody
