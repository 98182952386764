import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BrandHeader from "../../components/brand-headers/georgiaBrandHeader"
import BrandBody from "../../components/brand-bodies/georgiaBrandBody"

const GeorgiaBootsPage = () => {
  const pageTitle = "Georgia Boot"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />
        <BrandHeader />
        <BrandBody />
      </Layout>
      {/* description for search, hidden from page */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        "Since 1937, Georgia Boot has been building America's Hardest Working
        Boot. Purpose-built to meet the needs of the longest shift and toughest
        job, we're proud to make the boots you need to get the job done."
      </p>
    </>
  )
}

export default GeorgiaBootsPage

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Georgia Boot</title>
    <meta
      name="description"
      content="Since 1937, Georgia Boot has been building America's Hardest Working Boot. Purpose-built to meet the needs of the longest shift and toughest job, we're proud to make the boots you need to get the job done."
    />
  </>
)
